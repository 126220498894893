@import url('../fonts/Futura/stylesheet.css');
/* Tailwind things */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --font-primary: 'Courier New', Courier, monospace;

    --very-dark-grey: #222;
    --dark-grey: #555;
    --light-grey: #DADADA;
    --very-light-grey: #f8f8f8;
    
    --text-primary: color(--very-light-grey);
    --text-secondary: color(--light-grey);
    
    --font-h1: 'Lemon Milk';
}

/* Fonts
  - Futura
  - Futura Lt BT
  - 
*/

body {
    color: white;
    background-image: linear-gradient(rgba(23, 23, 141, 0.771), rgba(8, 106, 109, 0.742));
    padding-bottom: 150px;
}

html, body {
    overflow: auto;
    margin: 0;
    height: 100%;
}

h1 {
    font-family: 'Futura Hv BT';
    font-size: x-large;
    text-align: center;
}

h2 {
    font-family: 'Futura Lt BT';
    font-size: large;
    text-indent: 3%;
}

h3 {
    font-family: 'Futura Bk BT';
    font-size: medium;
    text-indent: 3%;
}

p {
    text-align: left;
    text-indent: 3%;
    font-size: small;
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 1.25;
}

a {
    color: rgb(199, 221, 255)
}

@media screen and (min-width: 800px) {
    h1 {
        font-family: 'Futura Hv BT';
        font-size: xx-large;
        text-align: center;
        padding-left: 8rem;
        padding-right: 8rem;
    }
    
    h2 {
        font-family: 'Futura Lt BT';
        font-size: x-large;
        text-indent: 3%;
        padding-left: 8rem;
        padding-right: 8rem;
    }
    
    h3 {
        font-family: 'Futura Bk BT';
        font-size: large;
        text-indent: 3%;
        padding-left: 8rem;
        padding-right: 8rem;
    }
    p {
        text-align: left;
        font-size: medium;
        text-indent: 3%;
        margin-bottom: 1rem;
        padding-left: 8rem;
        padding-right: 8rem;
    }
}