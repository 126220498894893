/* The header, the first part of the page */
.header {
    padding: 10px 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgba(81, 93, 188, 0.7);
    width: 100%;
}

/* The top bar containing the links and icons */
.topBar {
    width: 100%;
    height: 20%;
    padding: 10px;
    display: flex;
    justify-content: right;
    flex-direction: row-reverse;
    /* background-color: darkorange; */
}

/* The page title (says jack pauly) */
.pageTitleText {
    font-size: 4vh;
    font-family: 'Futura Hv BT';
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

nav {
    font-family: 'Futura';
    display: flex;
    justify-content: center;
    gap: 20px
}


a {
    text-decoration: none!important;
    color: white!important;
}

@media screen and (min-width: 800px) {
    nav a {
        font-family: 'Futura';
        font-size: x-large;
        margin: 10px;
    }    
}