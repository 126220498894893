/* The rules for the blog posts pages */
.blog-post {
  padding: 1rem;
  margin: 1rem;
  text-align: left;
}

/* TODO: Add more css stylings for this stylesheet, it will change and style the blogs themselves */

/* The rules for images in the blog page */
.blog_post img {
  object-fit: contain;
  display: block;
  margin: auto;
  margin-top: 2rem;
  width: 50%;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.blog_post p {
  /* line-height: 1.5rem; */
  font-family: 'Courier New', Courier, monospace;
}

.blog_post ul {
  text-indent: 5%;
  /* line-height: 1.5rem; */
  font-family: 'Courier New', Courier, monospace;
}