.tableContainer {
    width: 100%;
    max-width: 100%;
}

.resultsTable {
    display: block;
}

.resultsTable .row .cell button {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .resultsTable {
        font-size: 12px;
    }
    .searchResults {
        font-size: 12px;
    }
    .resultsTable .row .cell button {
        font-size: 10px;
    }
}

.searchResults {
    border: 1px solid black;
    
}

.searchResults .row {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr repeat(3, 1fr);
    border: 1px solid black;
} 

.searchResults .row .cell {
    /* text-overflow: clip; */
    overflow: hidden;
    max-height: 75px;
    text-align: center;
}

.resultsTable .row {
    display: grid;
    border: 1px solid black;
    grid-template-columns: 0.5fr 0.5fr 1.5fr repeat(3, 1fr);
    text-align: center;
}

.resultsTable .row_header {
    display: grid;
    border: 1px solid black;
    grid-template-columns: 0.5fr 0.5fr 1.5fr repeat(3, 1fr);
    text-align: center;
    padding: 2px;
    font-weight: bold;
}

.resultsTable .row .cell {
    /* text-overflow: clip; */
    overflow: hidden;
    max-height: 75px;
    text-align: center;
    vertical-align: middle;
}

.cell span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.reset {
    text-align: right;
}