@font-face {
    font-family: 'Futura Bold';
    src: url('Futura-Bold.eot');
    src: url('Futura-Bold.eot?#iefix') format('embedded-opentype'),
        url('Futura-Bold.woff2') format('woff2'),
        url('Futura-Bold.woff') format('woff'),
        url('Futura-Bold.ttf') format('truetype'),
        url('Futura-Bold.svg#Futura-Bold') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Lt BT';
    src: url('FuturaBT-LightItalic.eot');
    src: url('FuturaBT-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-LightItalic.woff2') format('woff2'),
        url('FuturaBT-LightItalic.woff') format('woff'),
        url('FuturaBT-LightItalic.ttf') format('truetype'),
        url('FuturaBT-LightItalic.svg#FuturaBT-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Bk BT';
    src: url('FuturaBT-BookItalic.eot');
    src: url('FuturaBT-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-BookItalic.woff2') format('woff2'),
        url('FuturaBT-BookItalic.woff') format('woff'),
        url('FuturaBT-BookItalic.ttf') format('truetype'),
        url('FuturaBT-BookItalic.svg#FuturaBT-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Bold 1';
    src: url('Futura-Bold_1.eot');
    src: url('Futura-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('Futura-Bold_1.woff2') format('woff2'),
        url('Futura-Bold_1.woff') format('woff'),
        url('Futura-Bold_1.ttf') format('truetype'),
        url('Futura-Bold_1.svg#Futura-Bold') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Md BT';
    src: url('FuturaBT-Medium.eot');
    src: url('FuturaBT-Medium.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-Medium.woff2') format('woff2'),
        url('FuturaBT-Medium.woff') format('woff'),
        url('FuturaBT-Medium.ttf') format('truetype'),
        url('FuturaBT-Medium.svg#FuturaBT-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Black Bold';
    src: url('Futura-Black-Bold.eot');
    src: url('Futura-Black-Bold.eot?#iefix') format('embedded-opentype'),
        url('Futura-Black-Bold.woff2') format('woff2'),
        url('Futura-Black-Bold.woff') format('woff'),
        url('Futura-Black-Bold.ttf') format('truetype'),
        url('Futura-Black-Bold.svg#Futura-Black-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura XBlk BT';
    src: url('FuturaBT-ExtraBlack.eot');
    src: url('FuturaBT-ExtraBlack.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-ExtraBlack.woff2') format('woff2'),
        url('FuturaBT-ExtraBlack.woff') format('woff'),
        url('FuturaBT-ExtraBlack.ttf') format('truetype'),
        url('FuturaBT-ExtraBlack.svg#FuturaBT-ExtraBlack') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Hv BT';
    src: url('FuturaBT-Heavy.eot');
    src: url('FuturaBT-Heavy.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-Heavy.woff2') format('woff2'),
        url('FuturaBT-Heavy.woff') format('woff'),
        url('FuturaBT-Heavy.ttf') format('truetype'),
        url('FuturaBT-Heavy.svg#FuturaBT-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura XBlk BT';
    src: url('FuturaBT-ExtraBlack_1.eot');
    src: url('FuturaBT-ExtraBlack_1.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-ExtraBlack_1.woff2') format('woff2'),
        url('FuturaBT-ExtraBlack_1.woff') format('woff'),
        url('FuturaBT-ExtraBlack_1.ttf') format('truetype'),
        url('FuturaBT-ExtraBlack_1.svg#FuturaBT-ExtraBlack') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Md BT';
    src: url('FuturaBT-BoldItalic.eot');
    src: url('FuturaBT-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-BoldItalic.woff2') format('woff2'),
        url('FuturaBT-BoldItalic.woff') format('woff'),
        url('FuturaBT-BoldItalic.ttf') format('truetype'),
        url('FuturaBT-BoldItalic.svg#FuturaBT-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Hv BT';
    src: url('FuturaBT-HeavyItalic.eot');
    src: url('FuturaBT-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-HeavyItalic.woff2') format('woff2'),
        url('FuturaBT-HeavyItalic.woff') format('woff'),
        url('FuturaBT-HeavyItalic.ttf') format('truetype'),
        url('FuturaBT-HeavyItalic.svg#FuturaBT-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura BdCn BT';
    src: url('FuturaBT-BoldCondensed.eot');
    src: url('FuturaBT-BoldCondensed.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-BoldCondensed.woff2') format('woff2'),
        url('FuturaBT-BoldCondensed.woff') format('woff'),
        url('FuturaBT-BoldCondensed.ttf') format('truetype'),
        url('FuturaBT-BoldCondensed.svg#FuturaBT-BoldCondensed') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Md BT';
    src: url('FuturaBT-MediumItalic.eot');
    src: url('FuturaBT-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-MediumItalic.woff2') format('woff2'),
        url('FuturaBT-MediumItalic.woff') format('woff'),
        url('FuturaBT-MediumItalic.ttf') format('truetype'),
        url('FuturaBT-MediumItalic.svg#FuturaBT-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Bk BT';
    src: url('FuturaBT-Book.eot');
    src: url('FuturaBT-Book.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-Book.woff2') format('woff2'),
        url('FuturaBT-Book.woff') format('woff'),
        url('FuturaBT-Book.ttf') format('truetype'),
        url('FuturaBT-Book.svg#FuturaBT-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura XBlkCnIt BT';
    src: url('FuturaBT-ExtraBlackCondItalic.eot');
    src: url('FuturaBT-ExtraBlackCondItalic.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-ExtraBlackCondItalic.woff2') format('woff2'),
        url('FuturaBT-ExtraBlackCondItalic.woff') format('woff'),
        url('FuturaBT-ExtraBlackCondItalic.ttf') format('truetype'),
        url('FuturaBT-ExtraBlackCondItalic.svg#FuturaBT-ExtraBlackCondItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Condensed Light';
    src: url('Futura-CondensedLight.eot');
    src: url('Futura-CondensedLight.eot?#iefix') format('embedded-opentype'),
        url('Futura-CondensedLight.woff2') format('woff2'),
        url('Futura-CondensedLight.woff') format('woff'),
        url('Futura-CondensedLight.ttf') format('truetype'),
        url('Futura-CondensedLight.svg#Futura-CondensedLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura LtCn BT';
    src: url('FuturaBT-LightCondensed.eot');
    src: url('FuturaBT-LightCondensed.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-LightCondensed.woff2') format('woff2'),
        url('FuturaBT-LightCondensed.woff') format('woff'),
        url('FuturaBT-LightCondensed.ttf') format('truetype'),
        url('FuturaBT-LightCondensed.svg#FuturaBT-LightCondensed') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Lt BT';
    src: url('FuturaBT-Light.eot');
    src: url('FuturaBT-Light.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-Light.woff2') format('woff2'),
        url('FuturaBT-Light.woff') format('woff'),
        url('FuturaBT-Light.ttf') format('truetype'),
        url('FuturaBT-Light.svg#FuturaBT-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Bold 2';
    src: url('Futura-Bold_2.eot');
    src: url('Futura-Bold_2.eot?#iefix') format('embedded-opentype'),
        url('Futura-Bold_2.woff2') format('woff2'),
        url('Futura-Bold_2.woff') format('woff'),
        url('Futura-Bold_2.ttf') format('truetype'),
        url('Futura-Bold_2.svg#Futura-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura MdCn BT';
    src: url('FuturaBT-MediumCondensed.eot');
    src: url('FuturaBT-MediumCondensed.eot?#iefix') format('embedded-opentype'),
        url('FuturaBT-MediumCondensed.woff2') format('woff2'),
        url('FuturaBT-MediumCondensed.woff') format('woff'),
        url('FuturaBT-MediumCondensed.ttf') format('truetype'),
        url('FuturaBT-MediumCondensed.svg#FuturaBT-MediumCondensed') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

