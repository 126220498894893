.contest {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.singleAlbum {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    overflow: hidden;
}

.singleAlbum .albumImage {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}

.singleAlbum img {
    width: 100%;
    height: auto;
}

.singleAlbum .text {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}