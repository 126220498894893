a {
    text-decoration: none;
}
.block {
    display: block;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 5px solid rgba(24, 181, 221, 0.67);
}

.blogTitle {
    font-family: 'Futura Hv BT';
    font-size: clamp(1rem, 3vw, 2rem);
    text-align: center;
}

.blogPreview {
    overflow-y: clip;
    mask-image: linear-gradient(180deg, #000 40%, transparent);
}