/* The links to the blogs (only shown when on the /blog/ page) */
.blog_container {
  margin: auto;
  max-width: 75rem;
}

.blog_links {
  margin: 0 1rem 0;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.blog_post p {
  display: block;
}