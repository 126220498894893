.album_row {
    margin-bottom: 20px;
    justify-content: center;
}

.album_col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.album_cover {
    width: 100%;
    max-width: 300px;
    background-size: cover;
    background-position: center;
}

.album_cover_blank {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-color: white;
}

@media (max-width: 767px) {
    .album_row {
        margin-bottom: 10px;
    }
}