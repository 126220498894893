.footerTable {
  width: 100%;
}

.footerTable tr {
  vertical-align: middle;
}

.footerTable td {
  vertical-align: top;
  /* width: 50%; */
}