.modalBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  position: fixed;
  max-width: 80%;
  height: 200px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Box shadow for the content */
  display: flex;
  flex-direction: column;
  z-index: 1001;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  color: black;
  border-bottom: 1px solid #ccc;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  display: inline-block;
  text-align: center;
  color: black;
}

.modalContainer .footer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

@media (max-width: 768px) {
  .modalContainer .body {
    display: inline-block;
    text-align: center;
    color: black;
    font-size: small;
  }
}