.image {
  margin-right: 20px;
  height: 20px;
  opacity: 50%;
  transition-duration: 0.1s;
}

@media screen and (min-width: 800px) {
  .image {
    margin-right: 40px;
    height: 40px;
  }    
}